export default [
  {
    path: '/industry',
    name: 'industry',
    component: () => import('@/views/website/industry/Industry'),
    meta: {
      pageTitle: '官网管理',
      breadcrumb: [
        {
          text: '行业洞察管理',
          active: true,
        },
      ],
    },
  },
    {
        path: '/industrySave',
        name: 'industrySave',
        component: () => import('@/views/website/newsSave'),
        meta: {
            pageTitle: '官网管理',
            breadcrumb: [
                {
                    text: '行业洞察管理',
                    to: '/industrySave',
                },
                {
                    text: '添加行业洞察',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/lawnews',
        name: 'lawnews',
        component: () => import('@/views/website/lawnews/Lawnews'),
        meta: {
            pageTitle: '官网管理',
            breadcrumb: [
                {
                    text: '法规要闻管理',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/lawnewsSave',
        name: 'lawnewsSave',
        component: () => import('@/views/website/newsSave'),
        meta: {
            pageTitle: '官网管理',
            breadcrumb: [
                {
                    text: '法规要闻管理',
                    to: '/lawnewsSave',
                },
                {
                    text: '添加要闻',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/website/news.vue'),
        meta: {
            layout: 'full',
        },
    },
]
