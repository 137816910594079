import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api'
import Vue from 'vue'
import { getUserData } from '@/auth/utils'
import Moment from 'moment'
/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

import { Message } from 'element-ui'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export function success(msg) {
  Message({
    dangerouslyUseHTMLString: true,
    showClose: false,
    message: `<div class="msg-success">${msg}</div>`,
    type: 'success',
    duration: 2000,
  })
}
export function error(msg) {
  Message({
    dangerouslyUseHTMLString: true,
    showClose: false,
    message: msg ? `<i class="iconfont icon-shibai"></i><span class="msg-success">${msg}</span>` : '<i class="iconfont icon-shibai"></i><span class="msg-success">失败</span>',
    type: 'error',
    duration: 2000,
  })
}

export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
  if (data.length === 0) return []
  const res = []
  const temp = {}

  for (let i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }

  for (let k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]].children) {
        temp[data[k][pid]].children = []
      }
      // eslint-disable-next-line no-underscore-dangle
      if (!temp[data[k][pid]]._level) {
        temp[data[k][pid]]._level = 1
      }
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      data[k]._level = temp[data[k][pid]]._level + 1
      temp[data[k][pid]].children.push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 权限
 * @param {*} key
 */
export function hasPermission(key) {
  // const buttonPerms = JSON.parse(localStorage.getItem('buttonPerms'))
  // const b = buttonPerms.indexOf(key) !== -1
  // return b || false
}

export function findBtnPerm(btnName) {
  // const buttonPerms = JSON.parse(localStorage.getItem('buttonPerms'))
  // const b = buttonPerms.indexOf(btnName) !== -1
  // return b || false
  // return hasPermission(btnName)
  return false
}

export function formatDateStr(value, pattern) {
  if (value === '') {
    return ''
  }
  let pp = pattern
  if (pattern === undefined || pattern === '') {
    pp = 'YYYY-MM-DD'
  }
  return Moment(value).format(pp)
}

export function navActiveInit(type, subTitle, parentTitle, parentRoute) {
  const navItems = document.getElementsByClassName('nav-item')
  // eslint-disable-next-line eqeqeq
  if (type == 1) {
    navItems.forEach(navTag => {
      const childTag = navTag.getElementsByClassName('menu-title')[0]
      if (childTag) {
        const title = navTag.getElementsByClassName('menu-title')[0].textContent
        if (title === subTitle) {
          navTag.className = 'nav-item active'
          setTimeout(() => {
            navTag.parentNode.style.display = 'block'
          }, 300)
        }
        if (title === parentTitle) {
          // eslint-disable-next-line no-param-reassign
          navTag.className = 'nav-item has-sub open sidebar-group-active'
        }
      }
    })
  } else {
    navItems.forEach(navTag => {
      const childTag = navTag.getElementsByClassName('menu-title')[0]
      if (childTag) {
        const title = navTag.getElementsByClassName('menu-title')[0].textContent
        if (title === subTitle) {
          if (this.$route.path === parentRoute) {
            navTag.className = 'nav-item active'
          } else {
            navTag.className = 'nav-item'
          }
        }
        if (title === parentTitle) {
          navTag.className = 'nav-item has-sub open sidebar-group-active'
        }
      }
    })
  }
}

// eslint-disable-next-line no-unused-vars
export function verifyEmali(rule, value, callback) {
  const emailReg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
  if (value === '') {
    // callback(new Error('邮箱不能为空'))
    callback()
  } else if (!emailReg.test(value)) {
    callback(new Error('邮箱格式不正确'))
  } else {
    callback()
  }
}
export function verifyPhone(rule, value, callback) {
  const patrn = /^1[3456789]\d{9}$/ // 手机电话
  const zjdh = /^((0\d{2,3})-)?(\d{7,8})$/ // 座机电话
  if (!value) {
    // return callback(new Error('电话号码不能为空'))
    callback()
  }
  setTimeout(() => {
    if (patrn.test(value) || zjdh.test(value)) {
      callback()
    } else {
      callback(new Error('电话号码格式不正确'))
    }
  }, 100)
}
export const verifyURL = (rule, value, callback) => {
  const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/
  if (!reg.test(value)) {
    callback(new Error('网站格式不正确'))
  } else {
    callback()
  }
}
// 校验是否包含中文
export const verifyIncludeChineseChar = (rule, value, callback) => {
  const reg = /[\u4E00-\u9FA5\uF900-\uFA2D]/
  if (reg.test(value)) {
    callback(new Error('不能输入中文'))
  } else {
    callback()
  }
}
export const changeBreadcrumb = (text) => {
  const breadcrumbLi = document.getElementById('detail-breadcrumb')
  const spanChild = breadcrumbLi.getElementsByTagName('span')[0]
  spanChild.innerHTML = text
}
export const enterpriseEvent = new Vue()
export const treeToList =(datas) => {
  var arr = []
  formateData(datas, 0)

  function formateData(datas, level) {
    var level = level || 0
    level++
    for (var i in datas) {
      arr.push({
        id: datas[i]['id'],
        component: datas[i]['component'],
        type: datas[i]['type'],
        name: datas[i]['name'],
      })
      if (datas[i].childResources) {
        formateData(datas[i].childResources, level)
      }
    }
  }
  return arr
}
export const getBtnPerms = (callback)=>{
  callback()
  // MenuNav()
  //   .then(res => {
  //     const resData = res.data
  //     const listData = treeToList(resData.data)
  //     var buttonPerms = []
  //     listData.forEach(l=>{
  //       if (l.type === 5 && l.component) {
  //         buttonPerms.push(l.component)
  //       }
  //     })
  //     callback(buttonPerms)
  //   })
}
export function verifyBtnPerm(buttonPerms,btnName) {
  return true
  // return buttonPerms.indexOf(btnName) !== -1
}

