import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'

import website from '@/router/routes/website'
import questionnaire from '@/router/routes/questionnaire'
import assessment from '@/router/routes/assessment'
import enterprise from './routes/enterprise'
import pages from './routes/pages'
import template from './routes/template'
import settings from './routes/settings'
import dictLibrary from './routes/dictLibrary'
import knowledge from './routes/knowledge'
import field from './routes/field'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...enterprise,
    ...pages,
    ...template,
    ...questionnaire,
    ...settings,
    ...assessment,
    ...website,
    ...dictLibrary,
    ...knowledge,
    ...field,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.name !== 'login' && to.name !== 'auth-login') {
    if (!isLoggedIn) return next({ name: 'auth-login' })
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
