export default [
  {
    path: '/dict-library',
    name: 'dict-library',
    component: () => import('@/views/dictLibrary/privacyField/privacyFieldView.vue'),
    meta: {
      pageTitle: '字典库',
      breadcrumb: [
        {
          text: '字典列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dict-label',
    name: 'dict-label',
    component: () => import('@/views/dictLibrary/dictLabel/DictLabel.vue'),
    meta: {
      pageTitle: '字典库',
      breadcrumb: [
        {
          text: '字典标签',
          active: true,
        },
      ],
    },
  },
]
