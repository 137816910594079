import {
  getToken,
} from '@/utils/token'

const getDefaultState = () => ({
  token: getToken(),
  userinfo: {},
})

const userState = getDefaultState()

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
}

export default {
  namespaced: true,
  state: userState,
  mutations,
}
