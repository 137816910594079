export default [
  {
    path: '/laws',
    name: 'laws',
    resource: 'laws',
    component: () => import('@/views/knowledge/laws/laws.vue'),
    meta: {
      pageTitle: '知识库管理',
      breadcrumb: [
        {
          text: '法律库',
          active: true,
        },
      ],
      resource: 'laws',
      action: 'manage',
    },
  },
  {
    path: '/lawDetail',
    name: 'lawDetail',
    component: () => import('@/views/knowledge/laws/lawsDetail.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '知识库管理',
      breadcrumb: [
        {
          text: '法律库',
          to: '/laws',
        },
        {
          text: '条纹详情',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lawType',
    name: 'lawType',
    resource: 'lawType',
    component: () => import('@/views/knowledge/lawType/LawTypes.vue'),
    meta: {
      pageTitle: '知识库管理',
      breadcrumb: [
        {
          text: '法律类型',
          active: true,
        },
      ],
      resource: 'lawType',
      action: 'manage',
    },
  },
  {
    path: '/lawCase',
    name: 'lawCase',
    resource: 'lawCase',
    component: () => import('@/views/knowledge/lawCase/lawCase.vue'),
    meta: {
      pageTitle: '知识库管理',
      breadcrumb: [
        {
          text: '案例管理',
          active: true,
        },
      ],
      resource: 'lawCase',
      action: 'manage',
    },
  },
  {
    path: '/lawCaseSave',
    name: 'lawCaseSave',
    component: () => import('@/views/knowledge/lawCase/LawCaseSave.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '知识库管理',
      breadcrumb: [
        {
          text: '案例管理',
          to: '/lawCase',
        },
        {
          text: '添加案例',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bestPractice',
    name: 'bestPractice',
    resource: 'bestPractice',
    component: () => import('@/views/knowledge/bestPractice/BestPractice.vue'),
    meta: {
      pageTitle: '知识库管理',
      breadcrumb: [
        {
          text: '最佳实践',
          active: true,
        },
      ],
      resource: 'bestPractice',
      action: 'manage',
    },
  },
  {
    path: '/lawUnscramble',
    name: 'lawUnscramble',
    resource: 'lawUnscramble',
    component: () => import('@/views/knowledge/lawUnscramble/lawsUnscramble.vue'),
    meta: {
      pageTitle: '知识库管理',
      breadcrumb: [
        {
          text: '法律解读',
          active: true,
        },
      ],
      resource: 'lawUnscramble',
      action: 'manage',
    },
  },
  {
    path: '/unscrambleSave',
    name: 'unscrambleSave',
    component: () => import('@/views/knowledge/lawUnscramble/LawsUnscrambleSave.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '知识库管理',
      breadcrumb: [
        {
          text: '法律解读',
          to: '/lawUnscramble',
        },
        {
          text: '添加解读',
          active: true,
        },
      ],
    },
  },
  {
    path: '/unscrambleDetail',
    name: 'unscrambleDetail',
    component: () => import('@/views/knowledge/lawUnscramble/UnscrambleDetail.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '知识库管理',
      breadcrumb: [
        {
          text: '法律解读',
          to: '/lawUnscramble',
        },
        {
          text: '解读最佳实践',
          active: true,
        },
      ],
    },
  },
]
