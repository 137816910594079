
const addListener = function(el, type, fn) {
  el.addEventListener(type, fn, false)
}
// 只禁止空格输入
const spaceFilter = function(el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/\s+/, '')
    el.dispatchEvent(new Event('input'))
  })
}
// 只能输入数字
const intFilter = function(el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/\D/g, '')
    el.dispatchEvent(new Event('input'))
  })
}
// 过滤特殊字符
const specialFilter = function(el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, '')
    el.dispatchEvent(new Event('input'))
  })
}
export default {
  bind(el, binding) {
    if (el.tagName.toLowerCase() !== 'input') {
      el = el.getElementsByTagName('input')[0] || el.getElementsByTagName('textarea')[0]
    }
    spaceFilter(el)
    switch (binding.arg) {
      case 'int':
        intFilter(el)
        break
      case 'special':
        specialFilter(el)
        break
      default:
        break
    }
  }
}

