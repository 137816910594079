export default [
  {
    path: '/settings/dictionaries',
    name: 'dictionaries',
    component: () => import('@/views/settings/dictionaries/Dictionaries.vue'),
    meta: {
      resource: 'dictionaries',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '字典管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/organization',
    name: 'organization',
    component: () => import('@/views/settings/organization/Organization.vue'),
    meta: {
      resource: 'organization',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '部门管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/user',
    name: 'user',
    component: () => import('@/views/settings/user/User.vue'),
    meta: {
      resource: 'user',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '用户管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/user/:id',
    name: 'user-edit',
    component: () => import('@/views/settings/user/UserEdit.vue'),
    meta: {
      resource: 'user',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '用户管理',
          to: {name:'user'},
        },
        {
          text: '用户编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/menu',
    name: 'menu',
    component: () => import('@/views/settings/menu/Menu.vue'),
    meta: {
      resource: 'menu',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '资源管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/role',
    name: 'role',
    component: () => import('@/views/settings/role/Role.vue'),
    meta: {
      resource: 'role',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '角色管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/role/:id',
    name: 'role-edit',
    component: () => import('@/views/settings/role/RoleEdit.vue'),
    meta: {
      resource: 'role',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '角色管理',
          to: {name:'role'},
        },
        {
          text: '角色编辑',
          active: true,
        },
      ],
    },
  },

  {
    path: '/settings/smtp',
    name: 'smtp',
    component: () => import('@/views/settings/email/SMTPSetting.vue'),
    meta: {
      resource: 'email',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '邮件服务器配置',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/notify',
    name: 'notify',
    component: () => import('@/views/settings/notify/Notify.vue'),
    meta: {
      resource: 'notify',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '通知管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/log',
    name: 'log',
    component: () => import('@/views/settings/log/LogList.vue'),
    meta: {
      resource: 'log',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '日志管理',
          active: true,
        },
      ],
    },
  },

]
