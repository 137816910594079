export default [
  {
    path: '/questionTemplate',
    name: 'questionTemplate',
    component: () => import('@/views/assess/questionTemplate/QuestionTemplate.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '评估管理',
      breadcrumb: [
        {
          text: '问卷管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/addQuestion',
    name: 'addQuestion',
    component: () => import('@/views/assess/questionTemplate/AddQuestion.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '评估管理',
      breadcrumb: [
        {
          text: '问卷管理',
          to: '/questionTemplate',
        },
        {
          text: '添加题目',
          active: true,
        },
      ],
    },
  },
  {
    path: '/linkRisk',
    name: 'linkRisk',
    component: () => import('@/views/assess/questionTemplate/LinkRisk.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '评估管理',
      breadcrumb: [
        {
          text: '问卷管理',
          to: '/questionTemplate',
        },
        {
          text: '关联风险',
          active: true,
        },
      ],
    },
  },
  {
    path: '/linkRiskSave',
    name: 'linkRiskSave',
    component: () => import('@/views/assess/questionTemplate/LinkRiskSave.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '评估管理',
      breadcrumb: [
        {
          text: '问卷管理',
          to: '/questionTemplate',
        },
        {
          text: '关联风险',
          active: true,
        },
      ],
    },
  },
  {
    path: '/risk',
    name: 'risk',
    component: () => import('@/views/assess/risk/Risk.vue'),
    meta: {
      resource: 'risk',
      pageTitle: '评估管理',
      breadcrumb: [
        {
          text: '风险管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/riskSave',
    name: 'riskSave',
    component: () => import('@/views/assess/risk/RiskSave.vue'),
    meta: {
      resource: 'risk',
      pageTitle: '评估管理',
      breadcrumb: [
        {
          text: '风险管理',
          to: '/risk',
        },
        {
          text: '添加风险',
          active: true,
        },
      ],
    },
  },
  {
    path: '/linkQuestion',
    name: 'linkQuestion',
    component: () => import('@/views/assess/risk/LinkQuestion.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '评估管理',
      breadcrumb: [
        {
          text: '风险管理',
          to: '/questionTemplate',
        },
        {
          text: '关联题目',
          active: true,
        },
      ],
    },
  },
  {
    path: '/riskCategory',
    name: 'riskCategory',
    component: () => import('@/views/assess/riskCategory/RiskCategory.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '评估管理',
      breadcrumb: [
        {
          text: '风险类别管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/assess/support.vue'),
    meta: {
      resource: 'assess',
      pageTitle: '评估管理',
      breadcrumb: [
        {
          text: '平台支撑管理',
          active: true,
        },
      ],
    },
  },
]
