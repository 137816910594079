export default [
  {
    path: '/template-policy',
    name: 'template-policy',
    component: () => import('@/views/template/policy/Template.vue'),
    meta: {
      resource: 'template-policy',
      pageTitle: '隐私政策模板',
      breadcrumb: [
        {
          text: '隐私政策模板',
          active: true,
        },
      ],
    },
  },
  {
    // path: '/template/policy/content/:id/:/t:/c',
    path: '/template/policy/content/:id/:t/:c',
    name: 'template-policy-content',
    component: () => import('@/views/template/TemplateEditor.vue'),
    meta: {
      pageTitle: '编辑模版内容',
      resource: 'template-policy',
      active: true,
      action: 'manage',
      breadcrumb: [
        {
          text: '隐私政策模板',
          active: true,
          to: '/template-policy',
        },
        {
          text: '编辑模版内容',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template-statement',
    name: 'template-statement',
    component: () => import('@/views/template/statement/Template.vue'),
    meta: {
      resource: 'template-statement',
      pageTitle: '隐私声明模板',
      breadcrumb: [
        {
          text: '隐私声明模板',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/statement/content/:id/:t/:c',
    name: 'template-statement-content',
    component: () => import('@/views/template/TemplateEditor.vue'),
    meta: {
      pageTitle: '编辑模版内容',
      resource: 'template-statement',
      active: true,
      action: 'manage',
      breadcrumb: [
        {
          text: '隐私声明模板',
          active: true,
          to: '/template-statement',
        },
        {
          text: '编辑模版内容',
          active: true,
        },
      ],
    },
  },
  {
    path: '/regime',
    name: 'regime',
    component: () => import('@/views/template/regime/Regime.vue'),
    meta: {
      action: 'manage',
      pageTitle: '模板管理',
      breadcrumb: [{
        text: '制度流程',
        active: true,
      }],
    },
  },
  {
    path: '/regime-save',
    name: 'regime-save',
    component: () => import('@/views/template/regime/RegimeSave.vue'),
    meta: {
      action: 'manage',
      pageTitle: '模板管理',
      breadcrumb: [
        {
          text: '制度流程',
          to: '/regime',
        },
        {
          text: '制度填写',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template-collect',
    name: 'template-collect',
    component: () => import('@/views/template/collect/Template.vue'),
    meta: {
      resource: 'template-collect',
      pageTitle: '合作协议模板',
      breadcrumb: [
        {
          text: '合作协议模板',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/collect/content/:id/:t/:c',
    name: 'template-collect-content',
    component: () => import('@/views/template/TemplateEditor.vue'),
    meta: {
      pageTitle: '编辑模版内容',
      resource: 'template-collect',
      active: true,
      action: 'manage',
      breadcrumb: [
        {
          text: '合作协议模板',
          active: true,
          to: '/template-collect',
        },
        {
          text: '编辑模版内容',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template-preview',
    name: 'template-preview',
    component: () => import('@/views/template/TemplatePreview.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/org',
    name: 'org',
    component: () => import('@/views/template/org/Org.vue'),
    meta: {
      resource: 'template-collect',
      pageTitle: '隐私组织',
      breadcrumb: [
        {
          text: '隐私组织',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orgSave',
    name: 'orgSave',
    component: () => import('@/views/template/org/OrgSave.vue'),
    meta: {
      pageTitle: '隐私组织',
      resource: 'template-collect',
      active: true,
      action: 'manage',
      breadcrumb: [
        {
          text: '隐私组织',
          to: '/org',
        },
        {
          text: '添加隐私组织',
          active: true,
        },
      ],
    },
  },
]
