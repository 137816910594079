export default [
  {
    path: '/enterprise/menu',
    name: 'resource',
    component: () => import('@/views/enterprises/resource/Resource.vue'),
    meta: {
      resource: 'enterprise',
      pageTitle: '企业管理',
      action: 'manage',
      breadcrumb: [
        {
          text: '企业资源',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sassenterprise',
    name: 'sassenterprise',
    resource: 'sassenterprise',
    component: () => import('@/views/enterprises/SassEnterprise.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: 'SASS用户管理',
          active: true,
        },
      ],
      resource: 'sassenterprise',
      action: 'manage',
    },
  },
  {
    path: '/sassenterprise/edit',
    name: 'sassenterprise-edit',
    component: () => import('@/views/enterprises/EnterprisesEdit.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: 'SASS用户管理',
          active: true,
          to: { name: 'sassenterprise' },
        },
        {
          text: 'SASS用户编辑',
          active: true,
        },
      ],
      resource: 'sassenterprise',
      action: 'manage',
    },
  },
  {
    path: '/sassenterprise/detail',
    name: 'sassenterprise-detail',
    component: () => import('@/views/enterprises/EnterprisesDetail.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: 'SASS用户管理',
          active: true,
          to: { name: 'sassenterprise' },
        },
        {
          text: 'SASS用户信息',
          active: true,
        },
      ],
      resource: 'sassenterprise',
      action: 'manage',
    },
  },
  {
    path: '/sassenterprise/auth',
    name: 'sassenterprise-auth',
    component: () => import('@/views/enterprises/EnterprisesAuth.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: 'SASS用户管理',
          active: true,
          to: { name: 'sassenterprise' },
        },
        {
          text: 'SASS用户授权',
          active: true,
        },
      ],
      resource: 'sassenterprise',
      action: 'manage',
    },
  },
  {
    path: '/enterprises',
    name: 'enterprises',
    resource: 'enterprises',
    component: () => import('@/views/enterprises/AutonomyEnterprise.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '独立部署管理',
          active: true,
        },
      ],
      resource: 'enterprises',
      action: 'manage',
    },
  },
  {
    path: '/enterprises/edit',
    name: 'enterprises-edit',
    component: () => import('@/views/enterprises/EnterprisesEdit.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '独立部署管理',
          active: true,
          to: { name: 'enterprises' },
        },
        {
          text: 'SASS用户编辑',
          active: true,
        },
      ],
      resource: 'enterprises',
      action: 'manage',
    },
  },
  {
    path: '/enterprises/detail',
    name: 'enterprises-detail',
    component: () => import('@/views/enterprises/EnterprisesDetail.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '独立部署管理',
          active: true,
          to: { name: 'enterprises' },
        },
        {
          text: '独立部署管理',
          active: true,
        },
      ],
      resource: 'enterprises',
      action: 'manage',
    },
  },
  {
    path: '/enterprises/auth',
    name: 'enterprises-auth',
    component: () => import('@/views/enterprises/EnterprisesAuth.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '独立部署管理',
          active: true,
          to: { name: 'enterprises' },
        },
        {
          text: '独立部署授权',
          active: true,
        },
      ],
      resource: 'enterprises',
      action: 'manage',
    },
  },
  {
    path: '/trialenterprise',
    name: 'trialenterprise',
    resource: 'trialenterprise',
    component: () => import('@/views/enterprises/TrialEnterprise.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '试用申请管理',
          active: true,
        },
      ],
      resource: 'trialenterprise',
      action: 'manage',
    },
  },
  {
    path: '/feedbackenterprise',
    name: 'feedbackenterprise',
    resource: 'feedbackenterprise',
    component: () => import('@/views/enterprises/FeedbackEnterprise.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '意见反馈管理',
          active: true,
        },
      ],
      resource: 'feedbackenterprise',
      action: 'manage',
    },
  },
]
