import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, IconsPlugin, VBToggle,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import loading from '@core/components/loading/index'
import '@/assets/scss/el-reset.css'
import confrim from '@core/utils/confrim'
// Global Components
import './global-components'
import infiniteScroll from 'vue-infinite-scroll'
import { formatDateStr, navActiveInit } from '@core/utils/utils'
ElementUI.Dialog.props.closeOnClickModal.default = false
// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
// 输入框指令
import inputFilter from '@/directives/input-filter/index.js'
import VueCropper from 'vue-cropper'
import Moment from 'moment'
import App from './App.vue'
import store from './store'
import router from './router'

// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ElementUI)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VBToggle)
Vue.use(loading)
Vue.use(infiniteScroll)
// Composition API
Vue.use(VueCompositionAPI)

Vue.prototype.$formatDate = formatDateStr
Vue.prototype.pageH = document.body.clientHeight
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// feather font icons
require('@core/assets/fonts/feather/iconfont.css')

Vue.prototype.navActiveInit = navActiveInit
Vue.config.productionTip = false
Vue.prototype.pageH = document.body.clientHeight
inputFilter(Vue)
Vue.use(VueCropper)
Vue.filter('formatDate', value => Moment(value).format('YYYY-MM-DD'))
Vue.prototype.formatDateStr = formatDateStr
Vue.prototype.autosize = { minRows: 3}
ElementUI.Dialog.props.closeOnClickModal.default = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
