export default [
  {
    path: '/questionnaire-template',
    name: 'questionnaire-template',
    component: () => import('@/views/questionnaire/template/TemplateList.vue'),
    meta: {
      resource: 'questionnaire-template',
      pageTitle: '问卷模板',
      breadcrumb: [
        {
          text: '问卷模板',
          active: true,
        },
      ],
    },
  },
  {
    path: '/questionnaire-type',
    name: 'questionnaire-type',
    component: () => import('@/views/questionnaire/type/TypeList.vue'),
    meta: {
      resource: 'questionnaire-type',
      pageTitle: '问卷类别',
      breadcrumb: [
        {
          text: '问卷类别',
          active: true,
        },
      ],
    },
  },
  {
    path: '/questionnaire-question',
    name: 'questionnaire-question',
    component: () => import('@/views/questionnaire/question/QuestionList.vue'),
    meta: {
      resource: 'questionnaire-question',
      pageTitle: '问卷题库',
      breadcrumb: [
        {
          text: '问卷题库',
          active: true,
        },
      ],
    },
  },
]
