export default [
  {
    path: '/field-label',
    name: 'field-label',
    component: () => import('@/views/field/label.vue'),
    meta: {
      pageTitle: '字典库',
      breadcrumb: [
        {
          text: '标签管理',
          active: true,
        },
      ],
    },
  },
]
